<template>
  <div class="box">
    <div v-if="onProgress === false">
      <boxes :statistic_count="statistic_count"
             :last_car_item="last_car_item"
             :last_archive_car_item="last_archive_car_item"
             :last_news_item="last_news_item"
      />
    </div>
    <loading-processing v-else></loading-processing>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";

Vue.component('boxes', (resolve) => {
  return require(['./boxes/index.vue'], resolve);
});

export default {
  name: 'dashboard',

  components: {},

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Главная',

    statistic_count: {
      cars: 0,
      archive_cars: 0,
      all_tasks: 0,
      call_center_tasks: 0,
      credit_tasks: 0,
      exchange_tasks: 0,
      news: 0,
      reviews: 0,
      seo: 0,
      employees: 0,
    },
    last_car_item: null,
    last_archive_car_item: null,
    last_news_item: null,

    onProgress: true,
  }),

  computed: {
    ...mapGetters("auth", ["authUser", "isAdmin"]),
  },

  async created() {
    try {
      this.EventBus.$on('update-dashboard-data', await this.getDashboardData);

      await this.getDashboardData();

      this.onProgress = false;

      await this.getLastCarItem();
      await this.getLastArchiveCarItem();
      await this.getLastNewsItem();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    async getDashboardData() {
      try {
        const [
          cars_statistic_count_response,
          archive_cars_statistic_count_response,
          tasks_statistic_count_response,
          news_statistic_count_response,
          reviews_statistic_count_response,
          seo_statistic_count_response,
          employees_statistic_count_response,
        ] = await axios.all([
          API.apiClient.get('/cars/statistic/count'),
          API.apiClient.get('/cars/archive/statistic/count'),
          API.apiClient.get('/tasks/statistic/count'),
          API.apiClient.get('/news/statistic/count'),
          API.apiClient.get('/reviews/statistic/count'),
          API.apiClient.get('/seo_items/statistic/count'),
          API.apiClient.get('/employees/statistic/count'),
        ]);

        this.statistic_count.cars = cars_statistic_count_response.data;
        this.statistic_count.archive_cars = archive_cars_statistic_count_response.data;
        this.statistic_count.new_tasks = tasks_statistic_count_response.data.new;
        this.statistic_count.incoming_tasks = tasks_statistic_count_response.data.incoming;
        this.statistic_count.main_tasks = tasks_statistic_count_response.data.main;
        this.statistic_count.main_incoming_tasks = tasks_statistic_count_response.data.main_incoming;
        this.statistic_count.personal_tasks = tasks_statistic_count_response.data.personal;
        this.statistic_count.all_tasks = tasks_statistic_count_response.data.all;
        this.statistic_count.sellers_tasks = tasks_statistic_count_response.data.sellers;
        this.statistic_count.call_center_tasks = tasks_statistic_count_response.data.call_center;
        this.statistic_count.credit_tasks = tasks_statistic_count_response.data.credit;
        this.statistic_count.exchange_tasks = tasks_statistic_count_response.data.exchange;
        this.statistic_count.search_tasks = tasks_statistic_count_response.data.search;
        this.statistic_count.estimate_tasks = tasks_statistic_count_response.data.estimate;
        this.statistic_count.detailing_tasks = tasks_statistic_count_response.data.detailing;
        this.statistic_count.news = news_statistic_count_response.data;
        this.statistic_count.reviews = reviews_statistic_count_response.data;
        this.statistic_count.seo = seo_statistic_count_response.data;
        this.statistic_count.employees = employees_statistic_count_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },

    async getLastCarItem() {
      try {
        const last_car_item_response = await API.apiClient.get('/cars', {
          params: {
            is_paginated: 0,
            items_limit: 1,
            sorting: 'desc',
            sort_column: 'updated_at',
          }
        });

        this.last_car_item = last_car_item_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },

    async getLastArchiveCarItem() {
      try {
        const last_archive_car_item_response = await API.apiClient.get('/cars/archive', {
          params: {
            is_paginated: 0,
            items_limit: 1,
            sorting: 'desc',
            sort_column: 'updated_at',
          }
        });

        this.last_archive_car_item = last_archive_car_item_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },

    async getLastNewsItem() {
      try {
        const last_news_item_response = await API.apiClient.get('/news', {
          params: {
            is_paginated: 0,
            items_limit: 1,
            sorting: 'desc',
            sort_column: 'updated_at',
            // only_cols: ['id', 'main_photo'],
          }
        });

        this.last_news_item = last_news_item_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
  },
}
</script>
